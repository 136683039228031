import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

export const Link = (props: any) => {
  const { noPrefetch, ...rest } = props;
  if (
    noPrefetch ||
    (typeof window !== 'undefined' &&
      window?.location?.pathname?.includes?.('/account/ambassador'))
  ) {
    // Ambassador pages must not prefetch, as it triggers a loading screen upon every hover interaction
    return <a {...props} href={props.to} />;
  }
  return <GatsbyLink {...rest} />;
};

export default Link;
