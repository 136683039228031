import React from 'react';
import { StyledYotpoStarRatings } from './YotpoStarRatings.styled';
import {getYotpoSlug} from '~/utils/yotpo-slugs';

export const YotpoStarRatings: React.FC<YotpoStarRatingsProps> = ({ slug }) => {
  const curUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <StyledYotpoStarRatings>
      <div
        className="yotpo bottomLine"
        data-product-id={getYotpoSlug(slug)}
        data-url={curUrl}
      ></div>
    </StyledYotpoStarRatings>
  );
};

interface YotpoStarRatingsProps {
  slug: string;
}
