import React, { PropsWithChildren } from 'react';
import { StyledRadio } from './Radio.style';

export const Radio: React.FC<RadioProps> = ({name, className, label, ...props}) => {
  return (
    <StyledRadio className={className}>
      <input type="radio" {...props} />
      <span>{label}</span>
    </StyledRadio>
  );
};

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;  
  label: string;
}
