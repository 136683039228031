import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from '../Link/Link';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { IntentSlug, IProduct } from '../../types/types';
import { ProductCardControls } from '../ProductCardControls/ProductCardControls';
import { StyledProductCard } from './ProductCard.style';
import useUser from '~/hooks/components/use-user';

export const ProductCard: React.FC<ProductCardProps> = ({
  intentCollections,
  product,
  noPrefetch,
  showSmallBtn = false, 
  showOnHover = true,
  isSharedOrder = false,
}) => {
  const { trackClickProduct } = useAnalytics();
  const { user } = useUser();
  const isAmbassadorRoute = typeof window !== 'undefined' && window.location.pathname.includes('/account/ambassador');
  const isAmbassador = !isAmbassadorRoute && user?.data?.roles?.includes('ambassador');
  const ambassadorDiscount = user?.data?.roles?.includes('New Ambassador (30% Off)') ? 0.7 : 0.8;

  const { name, variants } = product;
  const subscription = isAmbassador
    ? product.subscriptionForAmbassadors
    : product.subscription;
  const [{ slug, title: intentTitle }] =
    intentCollections && !!intentCollections.length
      ? intentCollections
      : [{ slug: '', title: '' }];
  const [{ mainImage }] = variants;
  const [{ sku, price, regularPrice }] = variants;

  const trackClick = async () => {
    await trackClickProduct(product.slug);
  };

  const subscriptionDiscount = isAmbassador ? ambassadorDiscount : product?.subscription?.discountPercentage;
  const oneTimeDiscount = isAmbassador ? ambassadorDiscount : 1;

  return (
    <StyledProductCard
      intentSlug={slug as IntentSlug}
      discountPercent={subscriptionDiscount}
    >
      <div className="padding">
        <span className="collection-pill">{intentTitle}</span>
        <Link
          to={`/products/${product.slug}`}
          onClick={trackClick}
          className={isSharedOrder ? 'shared-order' : ''}
          noPrefetch={!!noPrefetch}
        >
          <h6 className="product-name">{name}</h6>
        </Link>
        <Link
          to={`/products/${product.slug}`}
          onClick={trackClick}
          className={isSharedOrder ? 'shared-order' : ''}
          noPrefetch={!!noPrefetch}
        >
          <div className="image-container">
            <GatsbyImage
              image={mainImage.gatsbyImageData as IGatsbyImageData}
              alt={mainImage.title}
            />
          </div>
        </Link>
      </div>
      <ProductCardControls
        subscription={subscription}
        ambassadorDiscount={isAmbassador ? ambassadorDiscount : null}
        price={price}
        regularPrice={regularPrice}
        sku={sku}
        showOnHover={showOnHover}
        showSmallBtn={showSmallBtn}
        isSharedOrder={isSharedOrder}
      />
    </StyledProductCard>
  );
};

interface ProductCardProps {
  product: IProduct;
  showOnHover?: boolean;
  noPrefetch?: boolean;
  intentCollections: {
    title: string;
    slug: string;
  }[];
  showSmallBtn?: boolean;
  isSharedOrder?: boolean;
}
