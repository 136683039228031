import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import React, { useState } from 'react';
import { ProductSubscription } from '../../types/types';
import { useCartContext } from '../CartContext/CartContext';
import { Cta } from '../Generic/Cta/Cta';
import { Radio } from '../Generic/Radio/Radio';
import { StyledProductCardControls } from './ProductCardControls.style';

export const ProductCardControls: React.FC<ProductCardControlsProps> = ({
  subscription,
  price,
  regularPrice,
  sku,
  showOnHover = false,
  ambassadorDiscount = null,
  showSmallBtn = false,
  isSharedOrder = false,
}) => {
  const { cart } = useCart();
  const {
    openCart,
    addToCart,
    isEnrolmentInCart,
    addToWishlist,
    wishlistKits,
    wishlistProducts
  } = useCartContext();
  const itemInCart = !!cart.data?.lineItems?.filter(lineItem => (lineItem as any).variant.sku === sku).length;
  const productOnWishlist = !!wishlistProducts.filter((prod) => prod.sku === sku).length
  const kitOnWishlist = !!wishlistKits.filter((kit) => kit.sku === sku).length
  const itemOnWishlist = kitOnWishlist || productOnWishlist;
  const itemInCartIsSubscription = !!cart.data?.lineItems?.filter(lineItem => lineItem.variant.sku === sku && !!lineItem.subscriptionLineItems.length).length;
  const hasSubscription = !!subscription?.intervals?.[0]?.unit?.[0];
  const [subscribeSelected, setSubscribeSelected] = useState(hasSubscription);
  const discountPercentage = ambassadorDiscount
    ? (1 - ambassadorDiscount) * 100
    : subscription?.discountPercentage || 0;
  const discountPrice = discountPercentage
    ? ((100 - discountPercentage) / 100) * +regularPrice
    : 0;

  const subscriptionPrice =
    discountPrice % 1 === 0
      ? discountPrice.toString()
      : discountPrice.toFixed(2);

  const canAddToCart =
    !itemInCart ||
    (hasSubscription && subscribeSelected !== itemInCartIsSubscription);

  const setIsSubscribing = () => setSubscribeSelected(true);

  const setIsPurchasing = () => setSubscribeSelected(false);

  const cartLabel = canAddToCart ? 'Add to cart' : 'In cart';
  const wishlistLabel = !itemOnWishlist ? 'Add to wishlist' : 'On wishlist';

  const onClick = () => {
    if (!canAddToCart || (isSharedOrder && itemOnWishlist)) {
      openCart();
      return;
    }

    if (isSharedOrder) {
      addToWishlist(sku);
      return;
    }

    addToCart(sku, 1, subscribeSelected, subscription?.intervals[0]);
  };

  return (
    <StyledProductCardControls
      initiallyHidden={showOnHover}
      discountPercent={Math.round(discountPercentage)}
      className={[
        'product-card-controls',
        isSharedOrder ? 'product-card-controls--wishlist' : '',
      ].join(' ')}
    >
      {isSharedOrder && (
        <Cta
          onClick={onClick}
          href="#"
          disabled={isEnrolmentInCart || itemOnWishlist}
          className={`atc-btn wishlist-btn ${itemOnWishlist ? 'in-cart' : ''} ${
            showSmallBtn ? 'small-cta' : ''
          }`}
        >
          {showSmallBtn ? '+' : wishlistLabel}
        </Cta>
      )}
      {!isSharedOrder && (
        <>
          <div className="product-card-radios">
            {
              <>
                {hasSubscription && (
                  <Radio
                    name={'subscribe'}
                    label={`Monthly: $${subscriptionPrice}`}
                    checked={subscribeSelected}
                    onChange={setIsSubscribing}
                    className="subscription--radio"
                  />
                )}
                <Radio
                  name={'one-time-purchase'}
                  label={`One time: $${
                    ambassadorDiscount ? subscriptionPrice : price
                  }`}
                  checked={!subscribeSelected}
                  onChange={setIsPurchasing}
                  className={ambassadorDiscount ? 'subscription--radio' : null}
                />
              </>
            }
          </div>
          <Cta
            onClick={onClick}
            href="#"
            disabled={isEnrolmentInCart}
            className={`atc-btn ${!canAddToCart ? 'in-cart' : ''} ${
              showSmallBtn ? 'small-cta' : ''
            }`}
          >
            {showSmallBtn ? '+' : cartLabel}
          </Cta>
        </>
      )}
    </StyledProductCardControls>
  );
};

interface ProductCardControlsProps {
  subscription: ProductSubscription;
  ambassadorDiscount?: number | null;
  showOnHover?: boolean;
  sku: string;
  price: number;
  regularPrice: number;
  showSmallBtn?: boolean;
  isSharedOrder?: boolean;
}
