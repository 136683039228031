import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledFeaturedKits = styled.div`
  --gap: ${pxtorem(22)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: var(--gap);

  max-width: calc(685px * 2 + var(--gap));
  margin-inline: auto;
  align-items: stretch;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: minmax(335px, 1fr);
    justify-items: center;
  }
`;