import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';
import { useMediaQuery } from '@mantine/hooks';
import { Link } from '../Link/Link';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { IntentSlug, Kit } from '../../types/types';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { YotpoStarRatings } from '../Product/YotpoStarRatings/YotpoStarRatings';
import { ProductCardControls } from '../ProductCardControls/ProductCardControls';
import { StyledIntentPill, StyledKitProductCard } from './KitProductCard.style';
import useUser from '~/hooks/components/use-user';

export const KitProductCard: React.FC<KitProductCardProps> = ({
  kit,
  noPrefetch,
  isSharedOrder = false
}) => {
  const { trackClickProduct } = useAnalytics();

  const { user } = useUser();
  const isAmbassador = user?.data?.roles?.includes('ambassador');
  const ambassadorDiscount = user?.data?.roles?.includes('New Ambassador (30% Off)') ? 0.7 : 0.8;
  const subscription = isAmbassador
    ? kit?.subscriptionForAmbassadors
    : kit?.subscription;

  const subscriptionDiscount = isAmbassador ? ambassadorDiscount : kit?.subscription?.discountPercentage;

  const intents = kit.products.flatMap(product =>
    product.collection?.filter(p => !p.productType && !!p)
  );
  const uniqueIntents = intents.filter((intent, i) => {
    const searchIndex = intents.findIndex(
      f => f && intent && f.slug === intent?.slug
    );
    return searchIndex === i;
  });

  const trackClick = async () => {
    await trackClickProduct(kit.slug);
  };

  return (
    <StyledKitProductCard>
      <ShowOnScroll>
        <header>
          <div className="header-info">
            <Link
              noPrefetch={noPrefetch}
              to={`/bundles/${kit.slug}`}
              className={["kit--title", isSharedOrder ? 'shared-order' : ''].join(' ')}
              onClick={trackClick}
            >
              {kit.name}
            </Link>
            <div className="intents">
              {uniqueIntents.slice(0, 2).map(({ slug, name }) => {
                return (
                  <StyledIntentPill key={slug} intentSlug={slug as IntentSlug}>
                    {name}
                  </StyledIntentPill>
                );
              })}
            </div>
          </div>
          <div className="header-rating">
            <YotpoStarRatings slug={kit.slug} />
          </div>
        </header>
      </ShowOnScroll>
      <ShowOnScroll delay={50}>
        <Link to={`/bundles/${kit.slug}`} className={isSharedOrder ? 'shared-order' : ''} onClick={trackClick}>
          <GatsbyImage
            image={kit.mainImage.gatsbyImageData}
            alt={kit.name}
            objectFit="cover"
          />
        </Link>
      </ShowOnScroll>
      <ShowOnScroll delay={100}>
        <ProductCardControls
          subscription={subscription}
          ambassadorDiscount={isAmbassador ? ambassadorDiscount : null}
          price={kit.price}
          regularPrice={kit.regularPrice}
          isSharedOrder={isSharedOrder}
          sku={kit.sku}
        />
      </ShowOnScroll>
    </StyledKitProductCard>
  );
};

interface KitProductCardProps {
  kit: Kit;
  noPrefetch?: boolean;
  isSharedOrder?: boolean;
}
