import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledYotpoStarRatings = styled.div`
  .yotpo {
    a {
      font-family: var(--primaryFont);

      &:hover {
        color: var(--green) !important;
      }
    }
    .text-m {
      font-size: ${pxtorem(16)};
      font-weight: 500;
      margin-left: ${pxtorem(8)};
    }
  }
`;
