const slugMap: any = {
  "peppermint-tincture": "1000-mg-cbd-oil-peppermint",
  "melon-tincture": "1000-mg-full-spectrum-cbd-oil-melon",
  "mints": "15-mg-cbd-tablets-mint",
  "salve": "200-mg-cbd-salve-mint-rosemary",
  "whole-plant-softgels": "30-mg-cbd-softgels-full-spectrum-cbd",
  "relief-stick": "400-mg-cbd-stick-peppermint-eucalyptus",
  "peppermint-strips": "50-mg-cbd-strips-peppermint-10-pack",
  "tropical-punch-strips": "50-mg-cbd-strips-tropical-punch-10-pack",
  "womens-bundle": "women-s-wellness-bundle",
  "athletes-bundle": "athlete-s-bundle"
}
export const getYotpoSlug = (slug: string) => {
  return slugMap[slug] || slug
}