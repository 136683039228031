import styled from 'styled-components';
import { pxtorem } from '../../../utils/tools';

export const StyledRadio = styled.label`
  --dot-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: ${pxtorem(4)};
  cursor: pointer;
  font-weight: 400;
  &:focus-within {
    color: var(--black);
  }

  span {
    margin-left: 4px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;

    font: inherit;
    color: currentColor;
    max-width: 0px;
    max-height: 0px;
    border: 1px solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    margin-top: 4px;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: var(--dot-radius);
    height: var(--dot-radius);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;
