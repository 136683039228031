import styled from 'styled-components';
import { IntentSlug } from '../../types/types';
import { intentToColor } from '../../utils/colors';
import { pxtorem } from '../../utils/tools';

export const StyledProductCard = styled.article<StyledProductCardProps>`
  overflow: hidden;
  background-color: var(--gray);
  border-radius: 10px;
  width: 100%;
  min-width: 320px;

  aspect-ratio: 360 / 440;
  max-width: 360px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .padding {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: ${pxtorem(16)} ${pxtorem(24)};
    padding-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .collection-pill {
    text-transform: uppercase;
    color: ${({ intentSlug }) =>
      intentSlug === 'sleep-relaxation' ? 'var(--black)' : 'var(--white)'};
    background-color: ${({ intentSlug }) =>
      intentSlug ? intentToColor(intentSlug) : 'transparent'};
    font: 500 ${pxtorem(12)} / 1.175 ${props => props.theme.fonts.primary};
    border-radius: 100px;
    padding: ${pxtorem(10)} ${pxtorem(16)};
    margin-bottom: ${pxtorem(10)};
  }

  .product-name {
    font: 400 ${pxtorem(16)} / 1.175 ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    margin-bottom: ${pxtorem(16)};
  }

  .image-container {
    position: relative;
    overflow: hidden;

    margin-bottom: ${pxtorem(16)};
    width: 60%;
    margin: 0 auto;

    img {
      transform: translateZ(0) scale(1) translateZ(0);
      will-change: transform;

      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  
  .shared-order {
    pointer-events: none;
  }

  @media (min-width: 769px) {
    .padding {
      padding: ${pxtorem(24)} ${pxtorem(30)};
      padding-bottom: 0;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      .product-card-controls {
        transform: translateY(0);
        opacity: 1;
      }

      img {
        transform: translateZ(0) scale(1.03) translateZ(0);
      }
    }
  }
`;

interface StyledProductCardProps {
  intentSlug?: IntentSlug;
  discountPercent: number;
}
