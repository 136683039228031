import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledProductCardControls = styled.div<StyledProductCardControlsProps>`
  margin: ${pxtorem(16)} 0;

  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-inline: auto;
  max-height: 53px;

  &.product-card-controls {
    &--wishlist {
      display: flex;
      align-items: center;
    }
  }


  ${({ initiallyHidden }) =>
    initiallyHidden &&
    `
  opacity: 0;
  transition: transform 0.3s var(--cubic-ease), opacity 0.3s var(--cubic-ease);
  transform-origin: bottom;
  transform: translateY(10%);
  `}

  .product-card-radios {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .subscription--radio {
      position: relative;
    }

    .subscription--radio::after {
      content: '${({ discountPercent }) => discountPercent}% OFF';
      position: absolute;
      left: 104%;
      white-space: nowrap;
      font-size: 10px;
      color: var(--white);
      background-color: var(--green);
      text-transform: uppercase;
      border-radius: 10px;
      padding: 2px 8px;
      text-align: center;
      font-weight: 500;
      top: 2px;
    }
  }

  .atc-btn {
    max-width: 128px;

    &.in-cart {
      background-color: var(--black);
      color: var(--white);
    }
  }

  .wishlist-btn {
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
  }

  .small-cta {
    padding: 0;
    width: ${pxtorem(47)};
    height: ${pxtorem(47)};
    max-width: ${pxtorem(47)};
  }

  @media (max-width: 769px) {
    transform: translateY(0);
    opacity: 1;
    width: 90%;
  }

  @media (max-width: 900px) {
    .product-card-radios {
      font-size: 12px;
      line-height: 16px;

      span {
        margin-top: 2px;
      }
    }

    .atc-btn::not(.small-cta) {
      width: auto;
    }
  }
`;

interface StyledProductCardControlsProps {
  discountPercent: number;
  initiallyHidden: boolean;
}
