import styled from 'styled-components';
import { IntentSlug } from '../../types/types';
import { intentToColor } from '../../utils/colors';
import { pxtorem } from '../../utils/tools';
import { StyledShowOnScroll } from '../Generic/ShowOnScroll/ShowOnScroll.style';

export const StyledKitProductCard = styled.article`
  --padding-top: ${pxtorem(30)};
  --padding-inline: ${pxtorem(40)};
  background-color: var(--gray);
  border-radius: 12px;
  padding-top: var(--padding-top);
  padding-left: var(--padding-inline);
  padding-right: var(--padding-inline);
  padding-bottom: ${pxtorem(14)};
  /* aspect-ratio: 685 / 729; */
  max-height: 729px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${StyledShowOnScroll} {
    position: relative;

    & header {
      z-index: 5;
      background-color: var(--gray);
    }
  }

  header {
    display: inline-block;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row;
    position: relative;

    .header-info {
      display: flex;
      flex-direction: column;
      gap: ${pxtorem(8)};
    }

    .kit--title {
      display: inline-block;
      margin: 0;
      color: currentColor;
      text-decoration: none;
      font: 400 ${pxtorem(20)} / 1.175 ${props => props.theme.fonts.primary};

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 1024px) {
        font-size: ${pxtorem(16)};
      }
    }

    .intents {
      display: inline-flex;
      align-items: center;
      flex-flow: row;
      gap: ${pxtorem(10)};
    }

    .header-rating {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;

      svg {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg rect {
        width: ${pxtorem(20)};
        height: ${pxtorem(20)};

        @media (max-width: 868px) {
          width: 12px;
          height: 12px;
        }
      }

      p {
        margin: 0;
        white-space: nowrap;
        font: 500 ${pxtorem(16)} / 1.175 ${props => props.theme.fonts.primary};
        margin-left: ${pxtorem(4)};

        @media (max-width: 825px) {
          font-size: 12px;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    overflow: hidden;
    /* transform: translateY(50%); */
    /* position: absolute; */
    /* bottom: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, 50%); */
    margin: 32px auto;
    z-index: 1;
    display: grid;
    place-content: center;
    max-width: 464px;
    width: 80%;
    aspect-ratio: 1;

    img {
      object-fit: contain;
      transition: transform 0.3s var(--cubic-ease);
    }

    @media (max-width: 1280px) {
      max-width: 70%;
    }

    @media (max-width: 825px) {
      width: 62.5%;
    }
  }

  &:hover img {
    transform: scale(1.1);
  }

  .product-card-controls {
    width: 100%;
  }

  .shared-order {
    pointer-events: none;
  }

  
  @media (max-width: 1280px) {
    --padding-inline: ${pxtorem(20)};
    --padding-top: ${pxtorem(17)};
    padding-bottom: 0;
  }
  
  @media (max-width: 825px) {
    aspect-ratio: 335 / 357;
    width: 100%;
  }
`;

export const StyledIntentPill = styled.span<StyledIntentPillProps>`
  background-color: ${props => intentToColor(props.intentSlug)};
  color: ${({ intentSlug }) =>
    intentSlug === 'sleep-relaxation' ? 'var(--black)' : 'var(--white)'};
  text-transform: uppercase;
  white-space: nowrap;
  font: 500 12px / 1.175 ${props => props.theme.fonts.primary};
  padding: ${pxtorem(10)} ${pxtorem(16)};
  border-radius: 100px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

interface StyledIntentPillProps {
  intentSlug: IntentSlug;
}
